$(function () {
    //main menu
    $("#menutoggle,#menutogglehead").click(function () {
        $("#mainnav,#menutogglehead .menubutton").each(function () {
            $(this).toggleClass("show");
        });
    });

    //toggles
    $(".toggle").each(function () {
        $(this).parent().click(function () {
            $(this).toggleClass("show");
            ($(this).is(".order") ? $(this).find(".order-details") : $(this).next()).toggle(200);
        });
    });

    const catItems = document.getElementById("category-items");
    function resizeItems() {
        const items = $(catItems).children("li");
        const iw = items[0].offsetWidth, cw = catItems.offsetWidth;
        const itemsPerRow = iw < (cw / 3) ? 3 : iw < (cw / 2) ? 2 : 1;
        var maxHeight = 0, i;

        $(items).each(function () { $(this).css("min-height", "0"); });
        if (itemsPerRow > 1) for (i = 0; i < items.length; i += itemsPerRow) {
            maxHeight = items[i].offsetHeight;
            if (items[i + 1] != undefined) if (items[i + 1].offsetHeight > maxHeight) maxHeight = items[i + 1].offsetHeight
            if (items[i + 2] != undefined && itemsPerRow == 3) {
                if (items[i + 2].offsetHeight > maxHeight) maxHeight = items[i + 2].offsetHeight;
                $(items[i + 2]).css("min-height", maxHeight + "px");
            }
            $(items[i]).css("min-height", maxHeight + "px");
            if (items[i + 1] != undefined) $(items[i + 1]).css("min-height", maxHeight + "px");
        }
    }
    if (catItems) {
        resizeItems();
        window.onresize = resizeItems;
        $(catItems).find("img").each(function () { this.onload = resizeItems; });
    }
});



$(document).ready(function () {
    $("#message-close").click(function () {
        $("#message-panel").fadeOut("slow");
    });

});
